import { useState, useEffect, useMemo, Fragment } from 'react'
import {
  Row,
  Col,
  Card,
  Button,
  Popover,
  OverlayTrigger,
  Form,
  Modal,
} from 'react-bootstrap'
import {
  FaChartLine,
  FaChartArea,
  FaChartPie,
  FaChevronDown,
  FaChartBar,
  FaTh,
  FaQuestionCircle,
} from 'react-icons/fa'
import { RiGlobalLine } from 'react-icons/ri'
import GlassChart from '../assets/logos/magnifying-glass-chart-solid.svg'
import ModelContentInfobox from './ModelContentInfobox'
import CustomSelect from './CustomSelect'
import HelpTooltip from './HelpTooltip'
import {
  generateReport,
  getGraphDetails,
  getVersion,
  trainModel,
} from '../services/model'
import { useQueryClient, useQuery } from 'react-query'
import TrainProgress from './TrainProgress'
import OriginalSpend from './mmm-graphs/OriginalSpend'
import DownloadGraphOverlay from './mmm-graphs/DownloadGraphOverlay'
import PredictionChart from './mmm-graphs/PredictionChart'
import InsightsGraphCard from './InsightsGraphCard'
import MediaContribution from './mmm-graphs/MediaContribution'
import FunnelEffect from './mmm-graphs/FunnelEffect'
import TotalFunnelEffect from './mmm-graphs/TotalFunnelEffect'
import LagAndCarryover from './mmm-graphs/LagAndCarryover'
import DynamicSpend from './mmm-graphs/DynamicSpend'
import Influence from './mmm-graphs/Influence'
import BestSpend from './mmm-graphs/BestSpend'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../providers/AuthProvider'
import {
  defaultFormat,
  formatDateddmmyy,
  readableNumberMMM,
} from '../utility/format'
import ExportReport from './ExportReport'
import SpendContribution from './mmm-graphs/SpendContribution'
import { getModelStatus } from '../utility/model'
import CrashFallback from './CrashFallback'
import { Slider } from '@heroui/slider'
import ConfigureHyperparameters, {
  DEFAULT_PRIORS,
} from './ConfigureHyperparameters'
import GaugeMMM from './GaugeMMM'
import { config } from '../Constants'
import AIOverview from './AIOverview'

const TRAIN_OPTIONS = [
  { label: 'Fast', value: 1 },
  { label: 'Performance', value: 2 },
  { label: 'Accurate', value: 5 },
]

const VALID_KPI = new Set(['float64', 'Integer'])
const VALID_DATECOl = new Set(['Datetime', 'Integer'])

export default function ModelInsights({
  model,
  pushTitle,
  popTitle,
  graphLocation,
}) {
  const { t } = useTranslation()
  const { token, isEssential, version } = useAuth()

  const { data: graphDetails, isLoading: isLoadingsDetails } = useQuery(
    ['graphDetails', model?.id],
    async () => {
      const response = await getGraphDetails({ modelId: model.id, token })
      if (!response?.ok) throw new Error(response?.statusText)
      const graphDetails = await response.json()
      return graphDetails
    },
    { staleTime: Infinity },
  )

  const disableTrain = model?.version !== version

  const statsURL =
    'https://support.dataslayer.ai/understanding-advanced-statistics-in-marketing-mix-modeling-mmm'
  useEffect(() => {
    pushTitle(`Dataslayer MMM | Insights ${model?.name}`)
    return () => popTitle()
  }, [])

  const score = model?.score
  const popover = (
    <Popover
      id="popover-statistics"
      className={`${Array.isArray(score) ? '!min-w-[500px]' : ''}`}
    >
      <Popover.Header as="h3">
        <div className="flex justify-between items-center">
          <div>
            <span>{t('Advanced statistics')}</span>
          </div>
          <a className="link-stats" href={statsURL} target="_blank">
            <span className="inline-flex items-center align-middle text-base">
              <FaQuestionCircle className="me-2" />
            </span>
          </a>
        </div>
      </Popover.Header>
      <Popover.Body>
        {Array.isArray(score) ? (
          <Row className="min-w-[500px] px-3">
            <table className="">
              <thead className="py-2 text-[18px]">
                <tr>
                  <th></th>
                  <th>{t('Train')}</th>
                  <th>{t('Test')}</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(score[0]).map((metric) =>
                  metric === 'type' ? (
                    <Fragment key={metric} />
                  ) : (
                    <tr key={metric} className="py-2">
                      <td>{metric}</td>
                      <td>{defaultFormat({ num: score[0][metric] })}</td>
                      <td>{defaultFormat({ num: score[1][metric] })}</td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </Row>
        ) : (
          <table className="table-popover">
            <tbody>
              {model?.score ? (
                Object.keys(score).map((metric) => (
                  <tr key={metric}>
                    <td>{metric}</td>
                    <td>{defaultFormat({ num: score[metric] })}</td>
                  </tr>
                ))
              ) : (
                <p>{t('No advanced statistics available')}</p>
              )}
            </tbody>
          </table>
        )}
      </Popover.Body>
    </Popover>
  )
  const queryClient = useQueryClient()
  const dataslayerConfig = model.dataslayer_training_config

  const { minDate, maxDate } = useMemo(() => {
    const colData =
      model?.column_statistics?.[model?.dataslayer_training_config?.time_column]
    return {
      minDate: new Date(colData?.min),
      maxDate: new Date(colData?.max),
    }
  }, [model])
  const [testTrainSplit, setTestTrainSplit] = useState(
    model?.training_config?.test_train_split
      ? model?.training_config?.test_train_split * 100
      : 90,
  )
  const [datesMode, setDatesMode] = useState(false)

  const [hyperParameters, setHyperParameters] = useState(() => DEFAULT_PRIORS())
  const [configureParams, setConfigureParams] = useState(false)

  const [kpi, setKpi] = useState(() => {
    if (dataslayerConfig) {
      return {
        label: model.dataslayer_training_config.kpi,
        value: model.dataslayer_training_config.kpi,
      }
    }
    if (model?.training_config?.target)
      return {
        label: model?.training_config?.target,
        value: model?.training_config?.target,
      }
  })
  const [dateCol, setDateCol] = useState(() => {
    if (dataslayerConfig) {
      return {
        label: model.dataslayer_training_config.time_column,
        value: model.dataslayer_training_config.time_column,
      }
    }
    if (model?.training_config?.datetime_col)
      return {
        label: model.training_config.datetime_col,
        value: model.training_config.datetime_col,
      }
  })
  const [extraFeatures, setExtraFeatures] = useState(() => {
    if (dataslayerConfig) {
      return (
        model.dataslayer_training_config?.context_variables?.map((v) => ({
          label: v,
          value: v,
        })) ?? []
      )
    }
    return (
      model?.training_config?.extra_features_cols?.map((v) => ({
        label: v,
        value: v,
      })) ?? []
    )
  })
  const [columnsToIgnore, setColumnsToIgnore] = useState(() => {
    return (
      model?.training_config?.columns_to_ignore?.map((v) => ({
        label: v,
        value: v,
      })) ?? []
    )
  })
  const [trainingQuality, setTrainingQuality] = useState(() => {
    return TRAIN_OPTIONS.find(
      (v) => v.value === model?.training_config?.minutes,
    )
  })

  const [trainingRequest, setTrainingRequest] = useState(null)
  const [collapsed, setCollapsed] = useState(
    getModelStatus(model) === 'training',
  )
  const ignoredSet = new Set(columnsToIgnore.map((c) => c.value))
  let mediaChannelsCount = dataslayerConfig
    ? dataslayerConfig?.media_channels?.filter((m) => !ignoredSet.has(m))
        ?.length ?? 0
    : (model?.trainable_columns?.length ?? 0) -
      2 -
      columnsToIgnore?.length -
      extraFeatures?.length

  useEffect(() => {
    if (trainingRequest) {
      trainModel({
        modelId: model.id,
        trainConfig: trainingRequest,
        token,
      })
        .catch((e) => {
          //TODO notify user of error
        })
        .finally(() => {
          setCollapsed(true)
          setTrainingRequest(null)
          queryClient.invalidateQueries(['model', model.id])
          queryClient.invalidateQueries(['models', token])
          queryClient.invalidateQueries(['graphDetails', model?.id])
        })
    }
  }, [trainingRequest])

  const [activeCard, setActiveCard] = useState(isEssential ? 2 : 1)
  const RESULTS_GRAPHS = [
    {
      title: t('Response model accuracy'),
      helpText: t('Response model accuracy tooltip'),
      icon: <FaChartLine />,
      class: '',
      component: (
        <CrashFallback t={t} style={{ minHeight: '40vh' }} key={1}>
          <PredictionChart
            model={model}
            isInView={true}
            target={model?.training_config?.target}
            style={{ minHeight: '450px' }}
          />
        </CrashFallback>
      ),
      requirePro: model?.read_only ? false : true,
      detail: graphDetails?.['response_model_accuracy'],
      location: 'response_model_accuracy',
    },
    {
      title: t('Current media mix'),
      helpText: t('Current media mix tooltip'),
      icon: <FaChartPie />,
      class: '',
      component: (
        <CrashFallback t={t} style={{ minHeight: '40vh' }} key={2}>
          <OriginalSpend model={model} />
        </CrashFallback>
      ),
      detail: graphDetails?.['media_mix'],
      location: 'media_mix',
    },
    {
      title: t('Global effect'),
      helpText: t(
        'Percentage of Effect attributed to each variable in the model.',
      ),
      icon: <RiGlobalLine />,
      class: '',
      component: (
        <CrashFallback t={t} style={{ minHeight: '40vh' }} key={3}>
          <SpendContribution model={model} key={1} showRelative={false} />
        </CrashFallback>
      ),
      detail: graphDetails?.['global_effects'],
      location: 'global_effects',
    },
    {
      title: t('Media effect vs Spend'),
      helpText: t(
        'Percentage of Effect attributed to each media channel in the model VS the real Spend.',
      ),
      icon: <FaChartBar />,
      class: '',
      requirePro: model?.read_only ? false : true,
      component: (
        <CrashFallback t={t} style={{ minHeight: '40vh' }} key={4}>
          <SpendContribution
            model={model}
            showExtraFeatures={false}
            showRecommend={true}
            showSpend={true}
            requirePro={model?.read_only ? false : true}
            key={2}
          />
        </CrashFallback>
      ),
      detail: graphDetails?.['media_effect_spend'],
      location: 'media_effect_spend',
    },
    {
      title: t('Effect over time per week'),
      helpText: t('Media effects - weekly tooltip'),
      icon: <FaChartArea />,
      class: '',
      component: (
        <CrashFallback t={t} style={{ minHeight: '40vh' }} key={5}>
          <MediaContribution
            model={model}
            isInView={true}
            target={model?.training_config?.target}
            height={450}
          />
        </CrashFallback>
      ),
      detail: graphDetails?.['stacked_plot'],
      location: 'stacked_plot',
    },
    {
      title: t('Cross-channel impact'),
      helpText: t('Cross-channel impact tooltip'),
      icon: <FaTh />,
      class: '',
      component: (
        <CrashFallback t={t} style={{ minHeight: '40vh' }} key={6}>
          <FunnelEffect
            isInView={true}
            model={model}
            style={{ minHeight: '450px', maxHeight: '450px' }}
          />
        </CrashFallback>
      ),
      requirePro: model?.read_only ? false : true,
      detail: graphDetails?.['funnel_effect'],
      location: 'funnel_effect',
    },
    {
      title: t('KPI Summary breakdown'),
      helpText: t('KPI Summary Breakdown tooltip'),
      icon: <FaTh />,
      class: '',
      component: (
        <CrashFallback t={t} style={{ minHeight: '50vh' }} key={7}>
          <TotalFunnelEffect
            model={model}
            isInView={true}
            onChangeVariable={(variable) => {
              if (variable)
                graphLocation.current = ['kpi_summary_breakdown_', variable]
              else graphLocation.current = []
            }}
          />
        </CrashFallback>
      ),
      requirePro: model?.read_only ? false : true,
    },
    {
      title: t('Lag and carryover'),
      helpText: t('Lag and carryover tooltip'),
      icon: <FaChartLine />,
      class: 'lag-carryover-container',
      component: (
        <CrashFallback t={t} style={{ minHeight: '40vh' }} key={8}>
          <LagAndCarryover model={model} isInView={true} height={'450px'} />
        </CrashFallback>
      ),
      detail: graphDetails?.['lagcarryover'],
      location: 'lagcarryover',
    },
    {
      title: t('Ad to consumer response'),
      helpText: t('Ad to consumer response tooltip'),
      icon: <FaChartArea />,
      class: 'dynamic-spend-container',
      component: (
        <CrashFallback t={t} style={{ minHeight: '40vh' }} key={9}>
          <DynamicSpend model={model} isInView={true} />
        </CrashFallback>
      ),
      requirePro: model?.read_only ? false : true,
      detail: graphDetails?.['adstock'],
      location: 'adstock',
    },
    {
      title: t('Non media contribution'),
      helpText: t('Non media contribution tooltip'),
      icon: <FaChartArea />,
      class: 'influence-container',
      component: (
        <CrashFallback t={t} style={{ minHeight: '40vh' }} key={10}>
          <Influence model={model} isInView={true} />
        </CrashFallback>
      ),
      detail: graphDetails?.['non_media_contribution'],
      location: 'non_media_contribution',
    },
    {
      title: t('Shape effect'),
      helpText: t('Shape effect (diminishing returns) tooltip'),
      icon: <FaChartLine />,
      class: 'best-spend-container',
      component: (
        <CrashFallback t={t} style={{ minHeight: '40vh' }} key={11}>
          <BestSpend
            showDetail={true}
            model={model}
            isInView={true}
            target={model?.training_config?.target}
            onChangeChannel={(media) => {
              if (media) graphLocation.current = ['shape_effect', media]
            }}
          />
        </CrashFallback>
      ),
      requirePro: model?.read_only ? false : true,
      detail: graphDetails?.['shape_effect'],
    },
  ]
  useEffect(() => {
    const activeGraph = RESULTS_GRAPHS.find((card) => card.id === activeCard)
    if (activeGraph?.location) {
      graphLocation.current = [activeGraph.location]
    }
  }, [activeCard])

  RESULTS_GRAPHS.forEach((c, i) => (c.id = i + 1))

  const activeGraph = RESULTS_GRAPHS.find((card) => card.id === activeCard)
  const badColumns = (model?.trainable_columns ?? []).length < 3

  let accuracy = model?.accuracy
  if (accuracy < 0) accuracy = 0

  return (
    <Row>
      {config?.API_URL.includes('localhost') && (
        <Col>
          <Button
            onClick={() => {
              generateReport({
                modelId: model.id,
                token,
              })
            }}
          >
            Report
          </Button>
        </Col>
      )}
      <Col className={`${isEssential ? 'hidden' : ''}`} xs={12}>
        <ModelContentInfobox
          applyInfoboxClass={true}
          name={'insights_infobox'}
          collapsable={true}
          className="mb-2"
        >
          {badColumns ? (
            <span className="text-red-400 font-bold">
              {t('Insights infobox bad columns')}
            </span>
          ) : (
            t('Insights infobox')
          )}
        </ModelContentInfobox>
      </Col>
      {!model?.read_only && (
        <Col
          className={`train-container  pt-3 pb-5 rounded-2xl relative duration-200 ${collapsed ? 'max-h-0 overflow-hidden' : 'max-h-96'} ${isEssential ? 'hidden' : ''}`}
          xs={12}
        >
          <FaChevronDown
            size={20}
            className={`cursor-pointer right-7 absolute z-10 duration-200 ${collapsed ? '-rotate-90' : ''}`}
            onClick={() => setCollapsed(!collapsed)}
          />
          <Row className="ps-3">
            <Col xs={12} className={`text-xl mb-3  duration-200 font-medium`}>
              {t('Training configuration')}
              {disableTrain && (
                <HelpTooltip
                  message={t(
                    'Legacy model, to train this data upload it again and create a new model',
                  )}
                  className="ms-2 mt-1"
                />
              )}
            </Col>
            <Col
              xs={10}
              className={`${disableTrain ? 'opacity-50 pointer-events-none' : ''}`}
            >
              <Row className="px-2">
                <Col className="mb-3" xxl={3} md={4} xs={6}>
                  <Row>
                    <Col
                      className="font-thin mb-1 inline-flex items-center"
                      xs={12}
                    >
                      {t('Training quality')}
                      <HelpTooltip
                        message={t('Training quality tooltip')}
                        className="ms-2 mt-1"
                      />
                    </Col>
                    <Col xs={12}>
                      <CustomSelect
                        value={trainingQuality}
                        className="basic-single mt-2"
                        classNamePrefix="select"
                        isSearchable={true}
                        isDisabled={
                          !kpi ||
                          !dateCol ||
                          getModelStatus(model) === 'training'
                        }
                        placeholder={'Select...'}
                        isClearable={true}
                        onChange={(e) => setTrainingQuality(e)}
                        options={TRAIN_OPTIONS}
                      />
                    </Col>
                  </Row>
                </Col>
                {model?.version === 'v2' && (
                  <Col className={`mb-3`} xxl={3} md={4} xs={6}>
                    <Row>
                      <Col
                        className="font-thin mb-1 inline-flex items-center justify-between"
                        xs={12}
                      >
                        <span className="inline-flex items-center">
                          {t('Train-Test split')}
                          <HelpTooltip
                            message={t(
                              'The train-test split in MMM ensures the model can accurately predict outcomes on new data by training on one part and validating on another, preventing overfitting',
                            )}
                            className="ms-2 mt-1"
                          />
                        </span>
                        <span className="inline-flex items-center text-sm">
                          {t('Ratio')}
                          <Form.Check
                            type="switch"
                            className="mx-0 ms-2"
                            checked={datesMode}
                            onChange={(e) => setDatesMode((d) => !d)}
                          />
                          {t('Dates')}
                        </span>
                      </Col>
                      <Col className="" xs={12}>
                        <Row>
                          {datesMode ? (
                            <>
                              <Col xs={12} className="mt-2">
                                <Row>
                                  <Col
                                    className="font-thin text-xs text-start pe-0"
                                    xs={6}
                                  >
                                    {t('DATA USED FOR TRAINING: FROM')}
                                    <strong className="mx-1 text-strong font-white">
                                      {formatDateddmmyy(minDate, '/', true)}
                                    </strong>
                                    {t('TO')}
                                  </Col>
                                  <Col className="" xs={6}>
                                    <Form.Control
                                      className="text-input-mmm px-1"
                                      type="date"
                                      onChange={(e) => {
                                        const date = new Date(e.target.value)
                                        const t =
                                          (100 *
                                            (date.getTime() -
                                              minDate.getTime())) /
                                          (maxDate.getTime() -
                                            minDate.getTime())
                                        setTestTrainSplit(
                                          Math.min(Math.max(t, 20), 95),
                                        )
                                      }}
                                      value={
                                        new Date(
                                          minDate.getTime() +
                                            ((maxDate.getTime() -
                                              minDate.getTime()) *
                                              testTrainSplit) /
                                              100,
                                        )
                                          .toISOString()
                                          .split('T')[0]
                                      }
                                    />
                                    <div className="text-sm font-thin text-right">
                                      {t('Equivalent to {{eq}}', {
                                        eq: `${Number.parseInt(testTrainSplit)}%`,
                                      })}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col
                                className="!mt-[13px] slider-test-train"
                                xs={12}
                              >
                                <Slider
                                  value={testTrainSplit}
                                  color={'success'}
                                  minValue={20}
                                  maxValue={95}
                                  onChange={(e) => setTestTrainSplit(e)}
                                  showTooltip={false}
                                />
                              </Col>
                              <Col className="!text-[0.8rem] font-thin" xs={12}>
                                {t('DATA USED FOR TRAINING: {{value}}', {
                                  value: `${Number.parseInt(testTrainSplit)}%`,
                                })}
                              </Col>
                              <Col className="!text-[0.8rem] font-thin" xs={12}>
                                {t('DATA USED FOR TESTING: {{value}}', {
                                  value: `${100 - Number.parseInt(testTrainSplit)}%`,
                                })}
                              </Col>
                            </>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                {model?.version === 'v2' && (
                  <Col className="mb-3" xxl={3} md={4} xs={6}>
                    <Row className="ms-2">
                      <Col
                        className="font-thin mb-1 inline-flex items-center"
                        xs={12}
                      >
                        {t('Hyperparameters')}
                        <HelpTooltip
                          message={t(
                            'Configure advanced hyperparameters for the Bayesian model',
                          )}
                          className="ms-2 mt-1"
                        />
                      </Col>
                      <Col xs={12}>
                        <button
                          className="text-sm underline cursor-pointer mt-3 ps-0 !bg-[#00000000] py-0 !border-none"
                          onClick={() => setConfigureParams(true)}
                        >
                          {t('Configure')}
                        </button>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
            <Col xs={2}>
              <Row className="me-2">
                <Col className="font-thin opacity-0 select-none mb-1" xs={12}>
                  placeholder
                </Col>
                <Col>
                  {!disableTrain && (
                    <button
                      className="w-full button-mmm rounded-3xl py-2 bg-main disabled:bg-slate-950 disabled:pointer-events-none mt-2 font-bold"
                      disabled={
                        !kpi ||
                        !dateCol ||
                        trainingRequest ||
                        mediaChannelsCount > 100 ||
                        getModelStatus(model) === 'training'
                      }
                      onClick={() => {
                        setTrainingRequest({
                          target: kpi.value,
                          datetime_col: dateCol.value,
                          extra_features_cols: extraFeatures.map(
                            (f) => f.value,
                          ),
                          columns_to_ignore: columnsToIgnore.map(
                            (c) => c.value,
                          ),
                          minutes: trainingQuality?.value ?? 1,
                          test_train_split: testTrainSplit / 100,
                          priors: hyperParameters,
                        })
                      }}
                    >
                      {t('Train model')}
                    </button>
                  )}
                </Col>
                {!disableTrain && kpi?.value && dateCol?.value && (
                  <Col xs={12} className="text-center text-xs mt-2">
                    {mediaChannelsCount <= 100
                      ? t(`{{count}} media channels selected`, {
                          count: mediaChannelsCount,
                        })
                      : t(
                          `Maximum media channels exceeded, models with more than 100 media channels are not supported. Please ignore some columns.`,
                        )}
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      )}
      {getModelStatus(model) === 'training' && (
        <Col xs={12}>
          <TrainProgress model={model} onFail={() => setCollapsed(false)} />
        </Col>
      )}
      {(getModelStatus(model) === 'trained' ||
        getModelStatus(model) === 'optimizing') && (
        <Col
          className={`train-container mt-4 py-3 rounded-2xl relative duration-200 ${isEssential ? '!bg-transparent' : ''}`}
          xs={12}
        >
          <Row className="px-3">
            <Col xs={12}>
              <Row className="justify-between">
                <Col
                  xs="auto"
                  className={`text-xl mb-3 font-medium ${isEssential ? 'hidden' : ''}`}
                >
                  {t('Training results')}
                </Col>
                <Col className={`${isEssential ? 'hidden' : ''}`} xs="auto">
                  <ExportReport model={model} />
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row className="px-0">
                <Col className="flex px-1" xl={2} lg={3} md={4} xs={6}>
                  <Card className="results-card bg-transparent border-2 border-dashed border-white  text-white p-0 w-full my-2 max-h-[90px] min-h-[90px] !rounded-e-[0px] !border-l-[1px]">
                    <Card.Body className="px-0 py-0 flex justify-center items-center flex-col">
                      <Card.Title className="result-title !text-[14px]  inline-block whitespace-nowrap overflow-ellipsis text-truncate max-w-full">
                        <GaugeMMM
                          className=""
                          value={accuracy?.toFixed(2) ?? 0}
                        />
                      </Card.Title>
                      <Card.Subtitle className="result-label opacity-60 font-medium !text-[13px] inline-block whitespace-nowrap overflow-ellipsis text-truncate max-w-full !mt-[-7px]">
                        {t('Accuracy')}
                      </Card.Subtitle>
                      <Card.Subtitle className="result-label  font-medium  inline-block whitespace-nowrap overflow-ellipsis text-truncate max-w-full !text-[11px] mt-1 text-white">
                        {t('Higher is better')}
                      </Card.Subtitle>
                    </Card.Body>
                  </Card>
                  <Card className="results-card bg-transparent border-2 border-dashed border-white  text-white p-0 w-full my-2 max-h-[90px] min-h-[90px] !rounded-s-[0px] !border-l-[1px]">
                    <Card.Body className="px-0 py-0 flex justify-center items-center flex-col">
                      <Card.Title className="result-title !text-[14px]  inline-block whitespace-nowrap overflow-ellipsis text-truncate max-w-full">
                        <GaugeMMM
                          className=""
                          value={readableNumberMMM(
                            100 - (accuracy?.toFixed(2) ?? 0),
                            2,
                            4,
                          )}
                          invert={true}
                        />
                      </Card.Title>
                      <Card.Subtitle className="result-label opacity-60 font-medium !text-[13px] inline-block whitespace-nowrap overflow-ellipsis text-truncate max-w-full !mt-[-7px]">
                        {t('Margin error')}
                      </Card.Subtitle>
                      <Card.Subtitle className="result-label  font-medium  inline-block whitespace-nowrap overflow-ellipsis text-truncate max-w-full !text-[11px] mt-1 text-white">
                        {t('Lower is better')}
                      </Card.Subtitle>
                      <OverlayTrigger
                        rootClose={true}
                        trigger={['click']}
                        placement="auto"
                        delay={{ show: 100, hide: 100 }}
                        overlay={popover}
                      >
                        <div
                          className="tooltip-custom cursor-pointer"
                          style={{
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                          }}
                        >
                          <img
                            className="icon-item h-5 max-w-none"
                            src={GlassChart}
                          />
                          <span className="tooltip-text">
                            {t('Advanced statistics')}
                          </span>
                        </div>
                      </OverlayTrigger>
                    </Card.Body>
                  </Card>
                </Col>
                {RESULTS_GRAPHS.map((card, i) => (
                  <Col
                    key={i}
                    xl={2}
                    lg={3}
                    md={4}
                    xs={6}
                    className="my-2 px-1"
                  >
                    <InsightsGraphCard
                      key={card.id}
                      name={card.title}
                      icon={card.icon}
                      isActive={activeCard === card.id}
                      onClick={() => {
                        graphLocation.current = []
                        setActiveCard(card.id)
                      }}
                      helpText={card.helpText}
                      requirePro={card.requirePro}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xs={12} md={12} lg={12}>
              <Row className="my-2 px-2">
                <Card body className="insight-graph-card">
                  {activeCard !== null && (
                    <>
                      <DownloadGraphOverlay
                        title={activeGraph.title}
                        buttonsStyle={{ top: '0px', zIndex: 10 }}
                        disabled={activeGraph.requirePro && isEssential}
                        customFilename={activeGraph.title}
                      >
                        <Col
                          className={`mt-2 relative ${activeGraph.class}`}
                          xs={12}
                        >
                          {activeGraph.component}
                        </Col>
                        {activeGraph?.detail && (
                          <Col xs={12} className="mt-2 !text-xs">
                            <AIOverview
                              className="!ms-[5%] min-w-[90%]"
                              content={activeGraph?.detail}
                            />
                          </Col>
                        )}
                      </DownloadGraphOverlay>
                    </>
                  )}
                </Card>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
      <Modal
        show={configureParams}
        onHide={() => setConfigureParams(false)}
        className="model-import-dataslayer"
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ConfigureHyperparameters
            model={model}
            params={hyperParameters}
            onChange={setHyperParameters}
          />
        </Modal.Body>
      </Modal>
    </Row>
  )
}
