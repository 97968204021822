// Constants.js

const DATASLAYER_URL =
  import.meta.env.VITE_DATASLAYER_URL || 'https://app.dataslayer.ai'
const API_URL =
  import.meta.env.VITE_API_V1_URL || 'https://v1-morpheus-api.dataslayer.ai'
const API_V2_URL =
  import.meta.env.VITE_API_URL || 'https://morpheus-api.dataslayer.ai'

const prod = {
  API_URL: API_URL,
  DATASLAYER_URL: DATASLAYER_URL,
}

const prod_v2 = {
  API_URL: API_V2_URL,
  DATASLAYER_URL: DATASLAYER_URL,
}

const dev = {
  API_URL: `https://test-mmm-api.dataslayer.ai`,
  DATASLAYER_URL: 'https://test.dataslayer.ai',
}

const dev_v2 = {
  API_URL: `https://test-mmm-api-v2.dataslayer.ai`,
  DATASLAYER_URL: 'https://test.dataslayer.ai',
}

const queryString = window.location.search
const params = new URLSearchParams(queryString)
const version = params.get('version')

export function switchContext({ version, extraParams = {} }) {
  const query = window.location.search
  const params = new URLSearchParams(query)
  Object.entries(extraParams).forEach(([key, value]) => {
    params.set(key, value)
  })
  switch (version) {
    case 'v1':
      {
        params.set('version', 'v1')
        window.location.search = params.toString()
      }
      break
    default: {
      params.delete('version')
      window.location.search = params.toString()
      break
    }
  }
}

export const config = dev
export const HELP_URL = 'https://support.dataslayer.ai/morpheus' // TODO Discuss if we're gonna keep the help
